<script setup>
import {inject} from 'vue'
import {reactive} from 'vue'
import {register} from 'swiper/element/bundle'
import {useLocalePath} from 'vue-i18n-routing'
register()

const processedDataMenu = inject('processedDataMenu')
const internalMenuNav = getInternalNavMenu(processedDataMenu?.value, 'sectors')

const props = defineProps({
  content: {
    type: Object,
    required: true,
  },
  menuHero: {
    type: Object,
    required: true,
  },
})

const heroSectorsNavigation = internalMenuNav.menu_items.map((menuItem) => {
  const matchingHeroItem = props.menuHero.menu_items.find(
    (item) => item.slug === menuItem.slug
  )
  return matchingHeroItem
    ? {
        ...menuItem,
        assets: matchingHeroItem.assets,
        resource: matchingHeroItem.resource,
      }
    : menuItem
})

// console.log('heroSectorsNavigation', heroSectorsNavigation)

const localePath = useLocalePath()

const swiperContainer = ref()

// get sectors and resources for internal navigation
const sectors = reactive(props.menuHero.menu_items)

// console.log('sectors', sectors)

// show specific bg video or image - include lazy loading
function showBackground(event) {
  const bgElem = document.querySelector('.' + event.target.dataset.target)

  if (bgElem) {
    const video = bgElem.querySelector('video')
    const picture = bgElem.querySelector('picture')

    if (video) {
      const videoSource = video.querySelector('source')

      videoSource.src = videoSource.dataset.src
      video.load()
    } else if (picture) {
      const pictureSources = picture.querySelectorAll('source')
      const pictureImage = picture.querySelector('img')

      pictureSources.forEach((source) => {
        source.srcset = source.dataset.srcset
      })

      pictureImage.src = pictureImage.dataset.src
    }

    bgElem.classList.add('show')
  }
}

// hide sector background
function hideBackground(event) {
  const bgElem = document.querySelector('.' + event.target.dataset.target)

  if (bgElem) {
    bgElem.classList.remove('show')
  }
}

const bgElems = ref([])
const sectorNavItems = ref([])
const menuLinksTargetMobile = ref([])

// change specific bg video or image (for mobile) - include lazy loading
function changeBackgroundMobile(event) {
  // set active the current sector menu item
  sectorNavItems.value.forEach((elem) => {
    elem.classList.remove('active')
  })
  event.target.classList.add('active')

  // show the correct bg element
  const bgElem = document.querySelector('.' + event.target.dataset.target)

  if (bgElem) {
    const video = bgElem.querySelector('video')
    const picture = bgElem.querySelector('picture')

    if (video) {
      const videoSource = video.querySelector('source')

      videoSource.src = videoSource.dataset.src
      video.load()
    } else if (picture) {
      const pictureSources = picture.querySelectorAll('source')
      const pictureImage = picture.querySelector('img')

      pictureSources.forEach((source) => {
        source.srcset = source.dataset.srcset
      })

      pictureImage.src = pictureImage.dataset.src
    }

    // remove all the previous bgs
    bgElems.value.forEach((elem) => {
      elem.classList.remove('show')
    })

    bgElem.classList.add('show')
  }

  // show the corresponding navigation cta

  const menuLinkTarget = document.querySelector(
    '.menu-item-' + event.target.dataset.target
  )

  menuLinksTargetMobile.value.forEach((elem) => {
    elem.classList.remove('show')
  })

  menuLinkTarget.classList.add('show')
}

const swiperParams = {
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  slidesPerGroup: 1,
  slidesPerView: 'auto',
  watchOverflow: true,
}

function initializeSlider() {
  if (swiperContainer.value) {
    Object.assign(swiperContainer.value, swiperParams)
    swiperContainer.value.initialize()
  }
}

let initialized = false

onMounted(() => {
  if (!initialized) {
    setTimeout(() => {
      initializeSlider()
      initialized = true
    }, 1000)
  }
})

onBeforeUnmount(() => {
  if (swiperContainer.value && swiperContainer.value.swiper) {
    swiperContainer.value.swiper.destroy()
  }
})
</script>

<template>
  <section class="hero hero--home">
    <div class="hero-bgs">
      <video
        v-if="content.section.hero.intro_video"
        class="hero-video"
        autoplay
        muted
        loop
        playsinline
        preload
        :poster="content.section.hero.intro_image">
        <source
          :src="content.section.hero.intro_video"
          :type="getVideoType(content.section.hero.intro_video)" />
        <meta itemprop="name" content="Pininfarina" />
      </video>
      <div
        v-for="(sector, index) in heroSectorsNavigation"
        :key="index"
        class="hero-bg"
        :class="'hero-bg--' + index"
        ref="bgElems">
        <video
          v-if="sector.resource === 'video'"
          class="hero-video"
          autoplay
          muted
          loop
          playsinline
          preload>
          <source
            v-for="(asset, index) in sector.assets"
            :key="index"
            :data-src="asset.src"
            :type="getVideoType(asset.src)" />
          <meta itemprop="name" :content="sector.label + ' - Pininfarina'" />
        </video>
        <picture v-else-if="sector.resource === 'image'" class="hero-image">
          <source
            v-for="(asset, index) in filterPictureSourceTags(sector.assets)"
            :key="index"
            :data-srcset="asset.src"
            :type="asset.format"
            :media="asset.media" />
          <img
            v-for="(asset, index) in filterPictureImageTag(sector.assets)"
            :key="index"
            :data-src="asset.src"
            :alt="sector.label + ' - Pininfarina'" />
        </picture>
      </div>
    </div>
    <div class="hero-layer"></div>
    <div class="hero-content">
      <div class="hero-title" v-html="content.section.title"></div>
      <div class="hero-subtitle" v-html="content.section.description"></div>
      <div class="hero-sector-ctas">
        <ul class="menu-list">
          <li
            class="menu-item"
            :class="'menu-item-hero-bg--' + index"
            v-for="(sector, index) in heroSectorsNavigation"
            :key="index"
            ref="menuLinksTargetMobile">
            <NuxtLink class="menu-link" :to="localePath(sector.path)">
              {{ sector.label }}
              <div class="menu-link-btn btn btn--round-light">
                <svg class="icon icon--btn-round">
                  <use xlink:href="#icon-arrow"></use>
                </svg>
              </div>
            </NuxtLink>
          </li>
        </ul>
      </div>
    </div>
    <nav class="hero-navigation">
      <ul class="menu-list">
        <li class="menu-item" v-for="(sector, index) in heroSectorsNavigation" :key="index">
          <NuxtLink
            class="menu-link sensible"
            :to="localePath(sector.path)"
            :data-target="'hero-bg--' + index"
            @mouseover="showBackground"
            @mouseleave="hideBackground">
            <span class="menu-label">{{ sector.label }}</span>
            <div class="menu-link-btn btn btn--round-light">
              <svg class="icon icon--btn-round">
                <use xlink:href="#icon-arrow"></use>
              </svg>
            </div>
          </NuxtLink>
        </li>
      </ul>
      <div class="swiper swiper--hero-nav">
        <swiper-container
          init="false"
          ref="swiperContainer"
          events-prefix="swiper">
          <div slot="container-start">
            <div class="swiper-navigation">
              <div class="swiper-button swiper-button-prev sensible">
                <div class="btn btn--round btn--round-light">
                  <svg class="icon icon--btn-round icon--rotate-180">
                    <use xlink:href="#icon-arrow"></use>
                  </svg>
                </div>
              </div>
              <div class="swiper-button swiper-button-next sensible">
                <div class="btn btn--round btn--round-light">
                  <svg class="icon icon--btn-round">
                    <use xlink:href="#icon-arrow"></use>
                  </svg>
                </div>
              </div>
            </div>
          </div>
          <swiper-slide
            class="swiper-slide"
            v-for="(sector, index) in heroSectorsNavigation" :key="index">
            <div
              class="hero-sector-nav-item sensible"
              :data-target="'hero-bg--' + index"
              ref="sectorNavItems"
              @click="changeBackgroundMobile">
              {{ sector.label }}
            </div>
          </swiper-slide>
        </swiper-container>
      </div>
    </nav>
    {{}}
  </section>
</template>

<style lang="scss" scoped>
.hero--home {
  background-color: $color-blue;
  height: 100vh;
  overflow: hidden;
  position: relative;
  z-index: 0;

  &::before {
    background: linear-gradient(0deg, transparent 0%, $color-blue 100%);
    content: '';
    display: block;
    height: 12vh;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
  }

  &::after {
    background: linear-gradient(180deg, transparent 0%, $color-blue 100%);
    bottom: 0;
    content: '';
    display: block;
    height: 12vh;
    position: absolute;
    width: 100%;
    z-index: 1;
  }

  .hero-bg {
    bottom: 0;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: opacity $transition-default-slow 200ms;

    &.show {
      opacity: 1;
      transition: opacity $transition-default-slow 500ms;
    }
  }

  .hero-video {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: -1;

    @media (min-aspect-ratio: 16/9) {
      height: auto;
      width: 100%;
    }

    @media (max-aspect-ratio: 16/9) {
      height: 100%;
      width: auto;
    }
  }

  .hero-image {
    bottom: 0;
    left: 0;
    object-fit: cover;
    position: absolute;
    right: 0;
    top: 0;

    img {
      height: 100%;
      object-fit: cover;
      width: 100%;
    }
  }

  .hero-layer {
    background-color: rgba(darken($color-blue, 5%), 0.5);
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }

  .hero-content {
    @include make-col-ready();
    padding-top: pxrem(145);
    position: relative;
    z-index: 1;

    @include mq(md) {
      @include make-col(10);
      @include make-col-offset(1);
    }

    @include mq(lg) {
      @include make-col(8);
      @include make-col-offset(1);
    }

    @include mq(xl) {
      @include make-col(6);
      @include make-col-offset(1);
    }
  }

  .hero-title {
    @include display-1($color-white);
    font-family: $font-secondary;
    margin-bottom: pxrem(16);
    user-select: none;
  }

  .hero-subtitle {
    @include display-3($color-white);
    margin-bottom: pxrem(16);
    user-select: none;

    @include mq(lg) {
      margin-bottom: 0;
    }
  }

  .hero-sector-ctas {
    display: block;
    position: relative;

    @include mq(lg) {
      display: none;
    }

    .menu-list {
      @include list-reset;
    }

    .menu-item {
      left: 0;
      opacity: 0;
      pointer-events: none;
      position: absolute;
      top: 0;
      transition: opacity $transition-default;

      &.show {
        opacity: 1;
        pointer-events: all;
      }
    }

    .menu-link {
      @include label($color-white);
      text-decoration: none;
    }
  }

  .hero-navigation {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    z-index: 2;

    .menu-list {
      display: none;

      // big screen with mouse pointer
      @media (pointer: fine) {
        @include mq(lg) {
          @include list-reset;
          border-top: 1px solid $color-gray-blue;
          display: flex;
        }
      }

      .menu-item {
        border-right: 1px solid $color-gray-blue;
        flex: 1;
        flex-shrink: 0;
        min-height: 12vh;
        padding: pxrem(10) pxrem(12) pxrem(14);
        transition: backdrop-filter $transition-default;
        user-select: none;

        &:hover {
          backdrop-filter: blur(10px);
        }

        &:last-of-type {
          border-right: none;
        }
      }
    }

    .menu-link {
      @include label($color-white);
      display: flex;
      flex-direction: column;
      height: 100%;
      text-decoration: none;

      &:hover {
        .menu-link-btn {
          opacity: 1;
        }
      }
    }

    .menu-label {
      display: block;
      margin-bottom: pxrem(10);
    }

    .menu-link-btn {
      margin-top: auto;
      opacity: 0.2;
      transition: opacity $transition-default;
    }
  }

  .swiper--hero-nav {
    margin-bottom: pxrem(40);

    @include mq(lg) {
      @include make-col(22);
      @include make-col-offset(1);
    }

    // big screen without mouse pointer
    @media (pointer: not fine) {
      @include mq(lg) {
      }
    }

    // big screen with mouse pointer
    @media (pointer: fine) {
      @include mq(lg) {
        display: none;
      }
    }

    .swiper-navigation {
      margin-bottom: pxrem(25);
      padding-left: calc(#{$mobile-gutter} * 0.5);

      .swiper-button-prev {
        margin-right: pxrem(8);
      }
    }

    .swiper-wrapper {
      display: flex;
    }

    .swiper-slide {
      display: inline-block;
      margin-right: pxrem(25);
      width: auto;

      @include mq(lg) {
        margin-right: pxrem(40);
      }

      &:first-of-type {
        padding-left: calc(#{$mobile-gutter} * 0.5);
      }
    }

    .hero-sector-nav-item {
      @include paragraph($color-white);
      opacity: 0.3;
      transition: opacity $transition-default;
      width: auto;

      @include mq(lg) {
        font-size: $font-medium;
      }

      &:hover {
        opacity: 1;
      }

      &.active {
        opacity: 1;
      }
    }
  }
}
</style>
